<template>
  <div class="enterprises">
    <div class="enterprises-center position-relative">
      <img
        src="../../../assets/bg-img/ht-1.png"
        alt=""
        class="bg-img position-absolute bottom-0 left-0"
      />
      <div class="enterprises-posi position-absolute top-0 left-0">
        <div class="posi-choice text-main">
          <div class="font-bold title-posi">
            申请成为名企大厂
            <span
              >名企大厂是平台用户的重要标签，能获得更多人才的青睐，为你的职位带来更多查看与沟通</span
            >
          </div>
          <div>以下条件，满足任意三项即可申请</div>
          <el-checkbox-group v-model="checkList">
            <div class="check-div" v-for="(item, index) in dataCheck" :key="index">
              <el-checkbox :label="item.value">{{ item.label }}</el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
        <div class="enterprises-protocol direction">
          <div>
            <el-checkbox v-model="checked"
              >本人承诺，以上信息真实可信，如有虚假，平台有权将我的账号做冻结处理。</el-checkbox
            >
          </div>
          <div
            class="flex-center text-white bg-blue font-lighter submit-button pointer"
            @click="subminClick"
          >
            马上申请
          </div>
        </div>
      </div>
    </div>
    <mask-model
      title="申请已提交"
      :btnCancel="false"
      ref="mask"
      sureContent="返回"
      width="482px"
    >
      <div class="mask-soloe text-main font-normal" v-if="dachangObj">
        {{ dachangObj.status == 0 ? "待审核" : (dachangObj.status == 1 ? "通过" : "" )}}
      </div>
    </mask-model>
     <mask-model
      title="申请已驳回"
      :btnCancel="false"
      ref="maskBh"
      sureContent="确定"
      width="482px"
    >
      <div class="mask-soloe text-main font-normal" v-if="dachangObj">
        {{ dachangObj.rejection}}
      </div>
    </mask-model>
  </div>
</template>

<script>
import maskModel from "../../../components/mask.vue";
import toolApi from "../../../api/tool";
import api from "../../../api/enterprises";
export default {
  components: { maskModel },

  data() {
    return {
      checkList: [],
      dataCheck: [],
      checked: false,
      dataShow: false,
      dachangObj: null,
    };
  },

  created() {
    this.toolList();
   
  },

  mounted() {},

  methods: {
    // 查看申请状态
    getDachang() {
      api.getDachang().then((res) => {
        if (res.code == 200) {
          if (res.data) {
            if (res.data.status == 1 || res.data.status == 0) {
              this.dataShow = true;
            }
            let reason = this.dataCheck.filter((item) => {
              return res.data.reason.includes(item.label);
            });
            this.checkList=reason.map(item=>{
              return item.value
            })
            this.dachangObj = res.data;
            if(res.data.status==2){
              this.$refs.maskBh.show()
            }
          } else {
            this.dataShow = false;
          }
        }
      });
    },
    // 提交接口数据
    subminClick() {
      // 判断是否以及申请了
      if (this.dataShow) {
        this.$refs.mask.show();
        return;
      }
      if (!this.checked) {
        this.$util.msg("请勾选承诺", "warning");
        return;
      }
      if (this.checkList.length < 3) {
        this.$util.msg("请选择三项内容", "warning");
        return;
      }
      // 从原始数组中取出选中的值
      let reason = this.dataCheck.filter((item) => {
        return this.checkList.includes(item.value);
      });
      let personOne = reason.map((item) => {
        return item.label;
      });
      api.setDachang({ reason: personOne }).then((res) => {
        if (res.code == 200) {
          this.getDachang()
          this.$refs.mask.show();
        }
      });
    },
    // 获取申请成为名企的数据列表
    toolList() {
      toolApi.getUserConfig().then((res) => {
        if (res.code == 200) {
          this.dataCheck = res.data.famous.options;
           this.getDachang();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.enterprises {
  width: 100%;
  padding: 1.25rem 9.1875rem 2.3125rem 9.1875rem;
  .enterprises-center {
    width: 100%;
    height: 860px;
    background: #ffffff;
    box-shadow: 1px 0px 20px 1px #f6f6f8;
    .bg-img {
      width: 100%;
      height: 41.5rem;
      z-index: 0;
    }
    .enterprises-posi {
      width: 100%;
      height: 100%;
      z-index: 1;
      padding-top: 2.625rem;
      .posi-choice {
        padding-left: 13.1875rem;
        font-size: 15px;
        .title-posi {
          margin-bottom: 2.5rem;
          span {
            display: inline-block;
            color: #aeb1bb;
            font-size: 0.8125rem;
            margin-left: 1.875rem;
          }
        }
        .check-div {
          margin-top: 2.3125rem;
        }
      }
      .enterprises-protocol {
        width: 100%;
        margin-top: 4.375rem;
        .submit-button {
          width: 20.25rem;
          height: 2.625rem;
          margin-top: 2.5rem;
        }
      }
    }
  }
  .mask-soloe {
    margin-top: 1.0625rem;
    margin-bottom: 2rem;
  }
}
</style>
